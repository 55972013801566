<template>
  <div class="wallet-order form-group mt-4">
    <div class="row">
      <div class="alert alert-info col-md-10 offset-md-1">{{ $t('currency_exchange_alert') }}</div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-4">
        <label class="font-weight-bold">{{ $t('topuptxt') }}</label>
        <el-input
          type="number"
          size="large"
          precision="2"
          min="0"
          :step="0.50"
          v-model="model.value">
        </el-input>
      </div>
      <div class="col-md-12 mt-4 mb-4">
        <label class="font-weight-bold">{{ $t('paymentmethod') }}</label>
        <el-select
            v-model="model.method"
            placeholder="Select a provider for your top up"
            @change="onMethodSelected"
        >
          <el-option
              v-for="option in methods"
              :key="option.value"
              :label="option.label"
              :value="option"
          ></el-option>
        </el-select>
        <div v-if="fees" class="alert alert-primary mb-0 text-center mt-2 font-weight-bold">
          {{ fees }}
        </div>
      </div>
      <div class="col-md-12 mb-2">
        <label class="font-weight-bold">{{ $t('amount_charged') }}</label>
        <el-input
          v-model="model.charge"
          disabled
          class="bigger-symbol text-center">
          <template #prepend>{{ symbol }}</template>
          <el-button
            slot="append"
            icon="fa fa-refresh"
            :disabled="!allowSyncButton || canSubmit"
            v-on:click.prevent="syncTaxesButton()"
            class="btn btn-primary"
          />
        </el-input>
      </div>
      <div class="col-md-12 text-center mt-4">
        <p-button type="primary" v-loading="isLoading" :disabled="canSubmit || isLoading" @click="submit">{{ $t('submit') }}</p-button>
      </div>

    </div>
  </div>
</template>

<script>
import {Modal} from 'src/components/UIComponents'
import Constants from "@/assets/constants";
import {getCurrency} from "@/util/CurrencyUtils";
import {i18n} from "@/assets/i18n-plugin";
import {Tooltip} from "element-ui";
import {Button,Input} from "element-ui";
import Vue from "vue";

const rules = Constants.methods.getRules();
const user_data = JSON.parse(localStorage.getItem('user-info'));

const smIntegrationConfig = Constants.methods.smIntegrationConf();
const cpIntegrationConfig = Constants.methods.cpIntegrationConf();

Vue.use(Button)

export default {
  name: "wallet-order",
  components: {
    Modal,
    [Tooltip.name]: Tooltip,
    [Input.name]: Input,
    Button,
  },
  computed: {
    canSubmit() {
      if (this.model.value === undefined) {
          return false;
      }
      if (this.model.method.value === 'new_pix' || this.model.method.value === 'New Pix'){
          return this.model.value < 10 || this.model.charge <= 0;
      }
      return this.model.charge <= 0;
    },
    symbol() {
      return getCurrency(this.model && this.model.method ? (this.model.method.currency || '').toUpperCase() : '');
    },
  },
  data() {
    return {
      methods: [],
      defaultModel: {
        type: 'deposit',
        method: {},
        value: 0,
        exchange_rate: 0,
        charge: 0,
        userid: user_data.email,
        integration_data: {},
        order: {},
      },
      model: {},
      fees: '',
      loading: false,
      cptoken: '',
      tran_id: '',
      charge: '',
      isLoading: false,
      allowSyncButton: false,
      cp_rate: {},
      pix_rate: {},
    }
  },
  methods: {
    /*async renewApiToken() {
      try {
        const response = await fetch(`${cpIntegrationConfig.cpurl}/api/merchant/9/renew_api_token`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${cpIntegrationConfig.cpstk}`,
            'Accept': 'application/json'
          }
        });
        const result = await response.json();
        if (result.success) {
          this.cptoken = result.data;
        } else {
          console.error('Request failed:', result.msg);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },*/
    closeModal(navigate = false) {
      if (navigate) {
        this.$router.push({name: 'wallet_order_title', params: {order: this.model.order}});
        // this.$router.push({ name:'wallet_order_title'});
      }
      this.model = {...this.defaultModel};
    },
    async onMethodSelected() {
      await this.calcTaxes();
      const method = this.model.method;
      if (method.value) {
        const fixedTxt = `${this.$t('paysfixedfee')} $${method.fixed_fee}`;
        const percentTxt = `${this.$t('variablefee')} ${method.percent_fee}%`;
        const currencyTxt = `${this.$t('helper_excentry')} ${this.symbol}${this.model.exchange_rate}`;
        this.fees = `${fixedTxt} + ${percentTxt} * ${currencyTxt}`;
      } else {
        this.fees = '';
      }
      this.allowSyncButton = true
    },
    async syncTaxesButton() {
      this.allowSyncButton = false
      this.isLoading = true
      this.$toast.info(this.$t('requesting_exchange_rates'))
      this.model.exchange_rate = await this.getExchangeRate(this.model.value);
      this.isLoading = false
      setTimeout(() => {
        this.allowSyncButton = true
      }, 5000)
    },
    async calcTaxes() {
      this.isLoading = true
      // this.model.exchange_rate = await this.getExchangeRate(this.model.value);
      if(this.model.method.value !== 'new_pix' || this.model.method.value !== 'New Pix') {
          this.model.exchange_rate = parseFloat(this.pix_rate.data || 1).toFixed(4)
      }
      if (!this.model.method || this.model.method.currency === 'usd') {
        this.model.exchange_rate = parseFloat(1).toFixed(4);
      }
      if (this.model.value && this.model.method.value) {
        this.model.charge = parseFloat(
            (this.model.value * this.model.exchange_rate) +
            (
                this.model.method.fixed_fee * this.model.exchange_rate +
                this.model.value * this.model.method.percent_fee / 100 * this.model.exchange_rate
            )
        ).toFixed(2);
      } else {
        this.model.charge = parseFloat(0).toFixed(2);
      }
      this.isLoading = false
    },
    async handleSrMoneyAuth() {
      this.loading = true;
      const authPayload = {
        response_type: 'code',
        client_id: smIntegrationConfig.cid,
        state: new Date().getTime(),
      };
      window.onmessage = async (event) => {
        if (!event.data || (event.source !== authWin)) {
          return;
        }
        const code = event.data;
        if (!code) {
          this.$toast.warning(this.$t('operation_not_authorized'));
        } else {
          this.model.integration_data.code = code;
          await this.submitOrder();
        }
        window.onmessage = undefined;
        this.loading = false;
      }
      const authWin = this.$openPopUp(
          `${smIntegrationConfig.url}/oauthSM/authorize?${this.$serializeToQueryString(authPayload)}`,
          1024,
          720
      );
      let timer = setInterval(() => {
        if (authWin.closed) {
          clearInterval(timer);
          if (!this.model.integration_data.code) {
            this.$toast.warning(this.$t('operation_not_authorized'));
            window.onmessage = undefined;
            this.loading = false;
          }
        }
      }, 1000);
    },
    async handleCaravelPayAuth() {
      this.loading = true;
      //await this.renewApiToken();
      await this.submitOrder();
      this.cptoken = cpIntegrationConfig.cpmtk
      let cpp_url = cpIntegrationConfig.cppurl
      this.$openPopUp(
          `${cpp_url}/${this.cptoken}?charge=${this.charge}&currency=43&destinationCurrency=12&transactionId=${this.tran_id}&country=BR&coin=BRL`,
          1024,
          720
      );
    },
    async submitOrder() {
      this.loading = true;
      const response = await this.$createWalletOrder({
        ...this.model,
        method_id: this.model.method.id,
        integration_data: JSON.stringify(this.model.integration_data),
      }).then(response => {
        if (!this.$validateResponse(response)) {
          return;
        }
        this.model.order = response.data;
        this.tran_id = this.model.order.transaction_id
        // this.charge = this.model.order.value + ((this.model.method.percent_fee / 100) * this.model.order.value) + this.model.method.fixed_fee
        this.charge = this.model.order.charge
        this.loading = false;
        this.closeModal(true);
      }).catch(err => {
        this.$validateResponse(err.data);
        this.loading = false;
      });
    },
    async submit() {
      if (this.model.method.value === 'sm') {
        await this.handleSrMoneyAuth();
      } else if (this.model.method.value === 'new_pix' || this.model.method.value === 'New Pix') {
        await this.handleCaravelPayAuth();
      } else {
        await this.submitOrder();
      }
    },
    async getExchangeRate(value) {
      if (!this.model.method || this.model.method.currency === 'usd') {
        return parseFloat(1).toFixed(4);
      }
      if (this.model.method.value === 'new_pix' || this.model.method.value === 'New Pix'){
        let param = {}
        param.method = 'deposit'
        param.value = value
        const response = await this.$getWalletExchangeCP(param);
        return parseFloat(response.data || 1).toFixed(4);
      }
      const response = await this.$getWalletExchange(value);
      return parseFloat(response.data || 1).toFixed(4);
    },
  },
  async created() {
    this.defaultModel.exchange_rate = await this.getExchangeRate(0);
    this.methods = (await this.$getWalletMethods()).data || [];
    this.model = {...this.defaultModel};
    this.cp_rate = await this.$getWalletExchangeCP({method: 'depoist', value: 1}) || []
    this.pix_rate = await this.$getWalletExchange(1) || []
  },
}
</script>

<style scoped>
.bigger-symbol {
  font-size: large;
  font-weight: 900;
}

</style>
